import { Injectable } from '@angular/core';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { ReasonModel, ReasonObjectUI, ReasonType } from '../_core/models/reason.model';
import { ReasonsResources } from './reasons.resources';
import { ReasonState } from './reasons.state';
import { I18nService } from '@yourcause/common/i18n';

/**
 * Service for interacting with reasons.
 */
@AttachYCState(ReasonState)
@Injectable({ providedIn: 'root' })
export class ReasonService extends BaseYCService<ReasonState> {
  constructor (
    private i18n: I18nService,
    private reasonResources: ReasonsResources
  ) {
    super();
  }

  get allReasonsLookup () {
    return this.get('allReasonsLookup');
  }

  get allReasonsVisibleToNPO () {
    return this.get('allReasonsVisibleToNPO');
  }

  get allReasonsVisibleToYCAdmin () {
    return this.get('allReasonsVisibleToYCAdmin');
  }

  get allNonSystemReasons () {
    return this.get('allNonSystemReasons');
  }

  get allReasonsForNpoDisplay () {
    return this.get('allReasonsForNpoDisplayMap');
  }

  get allReasonsForYcAdminDisplay () {
    return this.get('allReasonsForYcAdminDisplayMap');
  }

  get deactivationReasonsSelect () {
    return this.get('deactivationReasonsSelect');
  }

  get checkReissueRequestReasonsSelect () {
    return this.get('checkReissueRequestReasonsSelect');
  }

  get vettingRequestRejectionReasonsSelect () {
    return this.get('vettingRequestRejectionReasonsSelect');
  }

  get bbgfEligibleReasonSelect () {
    return this.get('bbgfEligibleReasonSelect');
  }

  get bbgfIneligibleReasonSelect () {
    return this.get('bbgfIneligibleReasonSelect');
  }

  get disbursementSuspendedReasonSelect () {
    return this.get('disbursementSuspendedReasonsSelect');
  }

  get achInfoRejectionReasonsSelect () {
    return this.get('achInfoRejectionReasonsSelect');
  }

  get markAsFalsePositiveSelect () {
    return this.get('markAsFalsePositiveSelect');
  }
  get markAsTrueMatchSelect () {
    return this.get('markAsTrueMatchSelect');
  }

  get declineChapterRequestReasonsSelect () {
    return this.get('declineChapterRequestReasonsSelect');
  }

  get prospectiveAdminOutreachReasonSelect () {
    return this.get('prospectiveAdminOutreachReasonSelect');
  }

  get nonprofitAdminRequestRejectionReasonSelect () : ReasonObjectUI[] {
    return this.get('nonprofitAdminRequestRejectionReasonSelect') ?? [];
  }

  get nonprofitAdminRequestManagerApprovalReasonSelect () : ReasonObjectUI[] {
    return this.get('nonprofitAdminRequestManagerApprovalReasonSelect') ?? [];
  }

  get prospectiveAdminOptOutReasonSelect () {
    return this.get('prospectiveAdminOptOutReasonSelect');
  }

  get prospectiveAdminOutreachFilterReasons () {
    return this.get('prospectiveAdminOutreachFilterReasons');
  }

  async setAllReasonsVisibleToNPO () {
    const reasons = this.allReasonsLookup.filter((reason) => {
      return reason.visibleToNPO === true;
    });
    this.set('allReasonsVisibleToNPO', reasons);
  }

  async setAllReasonsVisibleToYCAdmin () {
    this.set('allReasonsVisibleToYCAdmin', this.allReasonsLookup);
  }

  async setAllNonSystemReasons () {
    const reasons = this.allReasonsLookup.filter((reason) => {
      return reason.systemReason === false;
    });
    this.set('allNonSystemReasons', reasons);
  }

  async setReasonsForDisplayMap () {
    const npoReasons = this.allReasonsVisibleToNPO.map((reason) => {
      return {
        i18nKey: reason.i18nKey,
        id: reason.id,
        typeId: reason.reasonTypeId,
        value: reason.reason
      };
    });
    const ycAdminReasons = this.allReasonsVisibleToYCAdmin.map((reason) => {
      return {
        i18nKey: reason.i18nKey,
        id: reason.id,
        typeId: reason.reasonTypeId,
        value: reason.reason
      };
    });
    this.set('allReasonsForNpoDisplayMap', npoReasons);
    this.set('allReasonsForYcAdminDisplayMap', ycAdminReasons);
  }

  async setDeactivationReasonsSelect () {
    const reasons = await this.setReasonsForSelectMap(
      ReasonType.NonprofitDeactivation
    );
    this.set('deactivationReasonsSelect', reasons);
  }

  async setCheckReissueRequestReasonsSelect () {
    const reasons = await this.setReasonsForSelectMap(
      ReasonType.CheckReissue
    );
    this.set('checkReissueRequestReasonsSelect', reasons);
  }

  async setVettingRequestRejectionReasonsSelect () {
    const reasons = await this.setReasonsForSelectMap(
      ReasonType.VettingRequestRejection
    );
    this.set('vettingRequestRejectionReasonsSelect', reasons);
  }

  async setBbgfEligibleReasonSelect () {
    const reasons = await this.setReasonsForSelectMap(
      ReasonType.EligibleForGiving
    );
    this.set('bbgfEligibleReasonSelect', reasons);
  }

  async setBbgfIneligibleReasonSelect () {
    const reasons = await this.setReasonsForSelectMap(
      ReasonType.IneligibleForGiving
    );
    this.set('bbgfIneligibleReasonSelect', reasons);
  }

  async setDisbursementSuspendedReasonSelect () {
    const reasons = await this.setReasonsForSelectMap(
      ReasonType.DisbursementSuspended
    );
    this.set('disbursementSuspendedReasonsSelect', reasons);
  }

  async setAchInfoRejectionReasonsSelect () {
    const reasons = await this.setReasonsForSelectMap(
      ReasonType.ACHInfoRejection
    );
    this.set('achInfoRejectionReasonsSelect', reasons);
  }

  async setMarkAsFalsePositiveSelect () {
    const reasons = await this.setReasonsForSelectMap(
      ReasonType.ScreeningFalsePositive
    );
    this.set('markAsFalsePositiveSelect', reasons);
  }

  async setMarkAsTrueMatchSelect () {
    const reasons = await this.setReasonsForSelectMap(
      ReasonType.ScreeningTrueMatch
    );
    this.set('markAsTrueMatchSelect', reasons);
  }

  async setDeclineChapterRequestReasonsSelect () {
    const reasons = await this.setReasonsForSelectMap(
      ReasonType.DeclineChapterRequest
    );
    this.set('declineChapterRequestReasonsSelect', reasons);
  }

  async setProspectiveAdminOptOutReasonSelect () {
    const reasons = await this.setReasonsForSelectMap(
      ReasonType.ProspectiveAdminOptOut
    );
    this.set('prospectiveAdminOptOutReasonSelect', reasons);
  }

  async setProspectiveAdminOutreachReasonSelect () {
    const reasons = await this.setReasonsForSelectMap(
      ReasonType.ProspectiveAdminOutreach
    );
    this.set('prospectiveAdminOutreachReasonSelect', reasons);
  }

  async setNonprofitAdminRequestRejectionReasonSelect () {
    const reasons = await this.setReasonsForSelectMap(
      ReasonType.NonprofitAdminRequestRejection
    );
    this.set('nonprofitAdminRequestRejectionReasonSelect', reasons);
  }

  async setNonprofitAdminRequestManagerApprovalReasonSelect () {
    const reasons = await this.setReasonsForSelectMap(
      ReasonType.NonprofitAdminRequestManagerApproval
    );
    this.set('nonprofitAdminRequestManagerApprovalReasonSelect', reasons);
  }

  async setProspectiveAdminOutreachFilterReasons () {
    const reasonByMapId: ReasonObjectUI[] = this.allReasonsLookup.filter((reason) => {
      return reason.reasonTypeId === ReasonType.ProspectiveAdminOutreach &&
        reason.isActive;
    }).map((reason) => {
      return {
        i18nKey: reason.i18nKey,
        id: reason.id,
        typeId: reason.reasonTypeId,
        value: reason.reason
      };
    });
    this.set('prospectiveAdminOutreachFilterReasons', reasonByMapId);
  }

  // get reasons by type, isActive and map for select
  async setReasonsForSelectMap (
    typeId: number
  ): Promise<ReasonObjectUI[]> {
    const reasonMapById = this.allNonSystemReasons.filter((reason) => {
      return reason.reasonTypeId === typeId &&
        reason.isActive;
    })
    .map((reason) => {
      return {
        i18nKey: reason.i18nKey,
        id: reason.id,
        typeId: reason.reasonTypeId,
        value: reason.reason
      };
    });

    return reasonMapById;
  }

  async setAllReasons (reasons: ReasonModel[]) {
    return Promise.all([
      this.set('allReasonsLookup', reasons),
      this.setAllReasonsVisibleToYCAdmin(),
      this.setAllReasonsVisibleToNPO(),
      this.setAllNonSystemReasons(),
      this.setReasonsForDisplayMap(),
      this.setDeactivationReasonsSelect(),
      this.setAchInfoRejectionReasonsSelect(),
      this.setCheckReissueRequestReasonsSelect(),
      this.setDisbursementSuspendedReasonSelect(),
      this.setVettingRequestRejectionReasonsSelect(),
      this.setBbgfIneligibleReasonSelect(),
      this.setBbgfEligibleReasonSelect(),
      this.setMarkAsFalsePositiveSelect(),
      this.setMarkAsTrueMatchSelect(),
      this.setDeclineChapterRequestReasonsSelect(),
      this.setProspectiveAdminOptOutReasonSelect(),
      this.setProspectiveAdminOutreachReasonSelect(),
      this.setNonprofitAdminRequestRejectionReasonSelect(),
      this.setNonprofitAdminRequestManagerApprovalReasonSelect()
    ]);
  }

  /**
   * Get all reasons and set on the state
   * IF reasons have not already been retrieved and set
   */
  async fetchAllReasonsLookup () {
    if (!this.allReasonsLookup) {
      const allReasons = await this.reasonResources.getAllReasons();

      await this.setAllReasons(allReasons);
    }
  }

  async fetchAllProspectiveAdminReasonSelect () {
    const allReasons = await this.reasonResources.getAllReasons();

    return Promise.all([
      this.set('allReasonsLookup', allReasons),
      this.setAllNonSystemReasons(),
      this.setProspectiveAdminOptOutReasonSelect(),
      this.setProspectiveAdminOutreachReasonSelect(),
      this.setProspectiveAdminOutreachFilterReasons()
    ]);
  }

  /**
   * get localized reason for display for the given reasonId.
   * @param reasonId
   * @returns
   */
  getLocalizedReason (reasonId: number) {
    const reason = this.allReasonsLookup[reasonId];
    if (reason) {
      return this.i18n.translate(
        reason.i18nKey,
        {},
        reason.reason
      );
    }

    return '';
  }
}
