import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { APIResponse, APIResult, PaginationOptions } from '@yourcause/common';
import { SubmittedSupportRequest, SupportRequestIssueDiagnosis } from '../../support-request/services/models';

@Injectable({ providedIn: 'root'})
export class SubmittedSupportRequestsResources {
  constructor (
    private httpRestService: HttpRestService
  ) { }

  /**
   * Fetch all Submitted Support Requests
   * @param paginationOptions
   * @returns paginated data
  */
  getSupportRequests (
    paginationOptions: PaginationOptions<SubmittedSupportRequest>
  ) {
    const endpoint = 'api/GetSupportRequests';
    return this.httpRestService.post<APIResult<SubmittedSupportRequest>>(
      endpoint,
      {
        paginationOptions
      }
    );
  }

  /**
   * Fetch all Support Requests Statuses
   * @returns support request statuses
  */
  getSupportRequestStatuses () {
    const endpoint = 'api/SupportRequests/Statuses';

    return this.httpRestService.get<APIResponse<string[]>>(endpoint);
  }

  /**
   *
   * @param payload: payload for endpoint
   * @returns: success boolean
   */
  createIssueDiagnosis (payload: SupportRequestIssueDiagnosis) {
    const endpoint = `api/SupportRequests/${payload.id}`;
    return this.httpRestService.put(
      endpoint,
      payload
    );
  }
}