export interface ReasonModel {
  i18nKey?: string;
  id: number;
  isActive: boolean;
  reason: string;
  reasonTypeId: number;
  systemReason: boolean;
  visibleToNPO: boolean;
}

export interface ReasonObjectUI {
  i18nKey?: string;
  id: number;
  typeId: number;
  value: string;
}

export enum ReasonType {
  DisbursementSuspended = 1,
  ACHInfoRejection = 2,
  NonprofitDeactivation = 3,
  CheckReissue = 4,
  CheckReissueRejection = 5,
  VettingRequestRejection = 6,
  IneligibleForGiving = 7,
  EligibleForGiving = 8,
  ScreeningFalsePositive = 9,
  ScreeningTrueMatch = 10,
  DeclineChapterRequest = 11,
  CancelChapterRequest = 12,
  ProspectiveAdminOutreach = 14,
  ProspectiveAdminOptOut = 15,
  ProspectiveNonprofitAdminCancellation = 16,
  NonprofitAdminRequestRejection = 17,
  NonprofitAdminRequestManagerApproval = 18
}

export enum ReasonOther {
  AchRejectionOther = 14,
  DeclineChapterRequestOther = 71,
  SuspendDisbursementOther = 8,
  IneligibleForGivingOther = 46,
  EligibleForGivingOther = 47,
  DeactivateNonprofitOther = 17,
  VettingRequestRejectionOther = 39,
  DonationRejectOther = 5,
  VolHoursRejectOther = 8,
  AdminRequestRejectionOther = 110,
  AdminRequestApprovalOther = 114,
}
