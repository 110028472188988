import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { APIResponse } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { NotifierService } from '@yourcause/common/notifier';
import { Observable, catchError, map, throwError } from 'rxjs';
import { ISupportItem, ISupportRequest, ISupportRequestAsset } from './models';
import { YcFile } from '@yourcause/common/files';

/**
 * Support Request Resources
 */
@Injectable({ providedIn: 'root' })
export class SupportRequestResources {

  constructor (
    private httpRestService: HttpRestService,
    private notifierService: NotifierService,
    private i18n: I18nService
  ) { }

  /**
   * Get Support Items
   *
   * @returns list of support items
   */
  getSupportItems (): Observable<ISupportItem[]> {
    const endpoint = `api/SupportItems`;

    return this.httpRestService.Get<APIResponse<ISupportItem[]>>(endpoint)
      .pipe(
        map(e => e.data),
        catchError(this.handleError.bind(this))
      );
  }

  /**
   * Create or Update a Support Request.
   */
  createSupportRequest (supportRequest: ISupportRequest): Observable<boolean> {
    const endpoint = `api/SupportRequests`;

    return this.httpRestService.Post<APIResponse<ISupportItem[]>>(endpoint, supportRequest)
      .pipe(
        map(_ => true),
        catchError(this.handleError.bind(this))
      );
  }

  /**
   * Upload and attach a asset (file) to a support request.
   */
  uploadAsset (supportRequestId: string, file: YcFile): Observable<ISupportRequestAsset> {
    const formData = new FormData();
    formData.append('asset', file.file, file.fileName);
    const endpoint = `api/SupportRequests/${supportRequestId}/Assets`;

    return this.httpRestService.Post<APIResponse<ISupportRequestAsset[]>>(endpoint, formData)
      .pipe(
        map(e => e.data[0]),
        catchError(this.handleError.bind(this))
      );
  }

  /**
   * Delete and remove a asset (file) from a support request.
   */
  deleteAsset (supportRequestId: string, assetId: string): Observable<boolean> {
    const endpoint = `api/SupportRequests/${supportRequestId}/Assets/${assetId}`;

    return this.httpRestService.Delete<APIResponse>(endpoint)
      .pipe(
        map(_ => true),
        catchError(this.handleError.bind(this))
      );
  }

  /**
   * Display an error based on status code to the user.
   *
   * @param error http error thrown
   * @returns throws the error so that the caller can handle it
   */
  private handleError (error: any): Observable<never> {
    switch (error.status) {
      case 406:
        this.notifierService.error(this.i18n.translate(
          'error:text406',
          {},
          'An error has occurred. Invalid input string or too many requests.'
        ));
        break;
      case 400:
        this.notifierService.error(error.error.detail);
        break;
      default:
        this.notifierService.error(this.i18n.translate(
          'error:textGeneric',
          {},
          'An error has occurred. Please try again later.'
        ));
    }

    return throwError(() => error);
  }
}
