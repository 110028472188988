import { Routes } from '@angular/router';
import { AuthWrapperComponent } from '@core/components/auth-wrapper/auth-wrapper.component';
import { UnAuthWrapperComponent } from '@core/components/unauth-wrapper/unauth-wrapper.component';
import { AuthGuard } from '@core/guards/auth.guard';
import { ReleaseNotesGuard } from '@core/guards/release-notes.guard';
import { RouteToSearchGuard } from '@core/guards/route-to-search.guard';
import { matchRequestResolver  } from '@core/resolvers/matchRequest.resolver';
import { termsOfServiceLinksResolver } from '@core/resolvers/terms-of-service-links.resolver';
import { customAlertsResolver } from './custom-alerts/custom-alerts.resolver';
import { featuredPartnerCollateralResolver } from './home/resolvers/featured-partner-collateral.resolver';
import { myNonprofitsResolver } from './my-nonprofits/my-nonprofits.resolver';
import { featureFlagResolver } from './platform-admin/resolvers/feature-flag.resolver';
import { allReasonsResolver } from './reasons/all-reasons.resolver';
import { allStatusesResolver } from './statuses/all-statuses.resolver';
import { SystemHealthDashboardPageComponent } from './system-health-dashboard/system-health-dashboard-page/system-health-dashboard-page.component';
import { programTypesResolver } from './programs/program-types.resolver';
import { prospectiveAdminOutreachReasonsResolver } from './prospective-admins/resolvers/prospective-admin-optout-reasons.resolver';
import { tourResolver } from '@core/resolvers/tour.resolver';
import { localeResolver } from '@core/resolvers/locale.resolver';
import { countriesResolver } from './location/countries.resolver';
import { activeNpoResolver } from '@core/resolvers/active-npo.resolver';
import { notificationsResolver } from './home/resolvers/notifications.resolver';
import { accountDetailsResolver } from '@core/resolvers/account-details.resolver';
import { pendingDisbursementsResolver } from '@core/resolvers/pending-disbursements.resolver';
import { unsetActiveNpoResolver } from '@core/resolvers/unset-active-npo.resolver';
import { myWorkspaceFinanceResolver } from './home/resolvers/my-workspace-finance.resolver';
import { communicationPreferencesResolver } from './my-account/resolvers/communication-preferences.resolver';
import { registrationAuthoritiesForNonprofitAddResolver } from './registration-authorities/registration-authorities-for-nonprofit-add.resolver';
import { SupportRequestStatusesResolver } from '@core/resolvers/support-request-statuses.resolver';

/**
 * App Routes
 */
export const routes: Routes = [{
  path: 'login',
  component: UnAuthWrapperComponent,
  loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
  data: {
    namespace: 'login'
  },
  resolve: {
    locale: localeResolver
  }
}, {
  path: 'grantterms',
  loadChildren: () => import('./grant-terms/grant-terms.module').then(m => m.GrantTermsModule),
  data: {
    namespace: 'grantterms'
  },
  resolve: {
    locale: localeResolver
  }
}, {
  path: 'support-request',
  loadChildren: () => import('./support-request/support-request.module').then(m => m.SupportRequestModule),
  data: {
    namespace: 'supportRequest'
  },
  resolve: {
    locale: localeResolver
  }
}, {
  path: 'nonprofits',
  loadChildren: () => import('./nonprofits-research/nonprofits-research.module').then(m => m.NonprofitsResearchModule),
  data: {
    namespace: 'nonprofits'
  },
  resolve: {
    locale: localeResolver
  }
}, {
  path: 'outreach',
  loadChildren: () => import('./admin-outreach-optout-page/admin-outreach-optout-page.module').then(m => m.AdminOutreachOptoutPageModule),
  data: {
    namespace: 'outreach'
  },
  resolve: {
    locale: localeResolver,
    outreachReasons: prospectiveAdminOutreachReasonsResolver
  }
}, {
  path: 'contact-outreach',
  loadChildren: () => import('./contact-outreach-optout-page/contact-outreach-optout-page.module').then(m => m.ContactOutreachOptoutPageModule),
  data: {
    namespace: 'contact-outreach'
  },
  resolve: {
    locale: localeResolver
  }
}, {
  path: 'admin',
  loadChildren: () => import('./blackbaud-sso/blackbaud-sso.module')
    .then(m => m.BlackbaudSsoModule)
}, {
  path: '',
  component: AuthWrapperComponent,
  resolve: {
    activeNpo: activeNpoResolver,
    accountDetails: accountDetailsResolver,
    featureFlags: featureFlagResolver,
    countries: countriesResolver,
    tours: tourResolver,
    termsOfServiceLinksResolver
  },
  canActivate: [
    AuthGuard
  ],
  canActivateChild: [
    ReleaseNotesGuard
  ],
  children: [{
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  }, {
    path: 'giving',
    loadChildren: () => import('./give/give.module').then(m => m.GiveModule),
    data: {
      breadcrumbLabel: 'Giving',
      namespace: 'give'
    },
    resolve: {
      locale: localeResolver,
      customAlerts: customAlertsResolver
    }
  }, {
    path: 'volunteering',
    loadChildren: () => import('./volunteer/volunteer.module').then(m => m.VolunteerModule),
    data: {
      breadcrumbLabel: 'Volunteer',
      namespace: 'volunteer'
    },
    resolve: {
      locale: localeResolver
    }
  },
  {
    path: 'nonprofit-profile',
    loadChildren: () => import('./nonprofit-profile/nonprofit-profile.module').then(m => m.NonprofitProfileModule),
    resolve: {
      locale: localeResolver
    }
  },
  {
    path: 'programs',
    loadChildren: () => import('./programs/programs.module').then(m => m.ProgramsModule),
    resolve: {
      locale: localeResolver,
      programTypes: programTypesResolver,
      allStatusesLookup: allStatusesResolver
    }
  },
  {
    path: 'stories',
    loadChildren: () => import('./nonprofit-stories/nonprofit-stories.module').then(m => m.NonprofitStoriesModule),
    data: {
      breadcrumbLabel: 'Stories',
      namespace: 'stories'
    },
    resolve: {
      locale: localeResolver,
      allStatusesLookup: allStatusesResolver
    }
  },
  {
    path: 'chapters',
    loadChildren: () => import('./chapters/chapters.module').then(m => m.ChaptersModule),
    data: {
      breadcrumbLabel: 'Chapters',
      namespace: 'nonprofits'
    },
    resolve: {
      locale: localeResolver
    }
  }, {
    path: 'my-workspace',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    resolve: {
      pendingDisbursements: pendingDisbursementsResolver,
      matchRequests: matchRequestResolver,
      financeStats: myWorkspaceFinanceResolver,
      customAlerts: customAlertsResolver,
      nonprofitNotifications: notificationsResolver,
      featuredPartnerCollateral: featuredPartnerCollateralResolver
    }
  }, {
    path: 'implementation',
    loadChildren: () => import('./implementation/implementation.module').then(m => m.ImplementationModule),
    resolve: {
      locale: localeResolver
    },
    data: {
      namespace: [
        'admin'
      ]
    }
  }, {
    path: 'account-setup',
    loadChildren: () => import('./nonprofit-account-setup/nonprofit-account-setup.module').then(m => m.NonprofitAccountSetupModule),
    canActivate: [RouteToSearchGuard],
    resolve: {
      locale: localeResolver,
      customAlerts: customAlertsResolver
    },
    data: {
      breadcrumbLabel: 'Administration',
      namespace: [
        'nonprofits',
        'accountSetup',
        'claim',
        'compliance',
        'sdgs',
        'contact',
        'bankInfo'
      ]
    }
  }, {
    path: 'my-nonprofits',
    loadChildren: () => import('./my-nonprofits/my-nonprofits.module').then(m => m.MyNonprofitsModule),
    data: {
      breadcrumbLabel: 'My Nonprofits',
      namespace: ['claim', 'nonprofits']
    },
    resolve: {
      locale: localeResolver,
      myNonprofits: myNonprofitsResolver,
      allReasonsLookup: allReasonsResolver,
      allStatusesLookup: allStatusesResolver
    }
  }, {
    path: 'user-management',
    loadChildren: () => import('./user-management/user-management.module').then(m => m.UserManagementModule),
    data: {
      breadcrumbLabel: 'User Management'
    },
    resolve: {
      locale: localeResolver,
      allReasonsLookup: allReasonsResolver,
      allStatusesLookup: allStatusesResolver
    }
  }, {
    path: 'my-account',
    loadChildren: () => import('./my-account/my-account.module').then(m => m.MyAccountModule),
    data: {
      namespace: ['login', 'account']
    },
    resolve: {
      locale: localeResolver,
      unsubscribeEmailClassIds: communicationPreferencesResolver
    }
  }, {
    path: 'notes',
    loadChildren: () => import('./notes/notes.module').then(m => m.NotesModule)
  }, {
    path: 'address-requests',
    loadChildren: () => import('./address-requests/address-requests.module').then(m => m.AddressRequestsModule)
  }, {
    path: 'support',
    loadChildren: () => import('./support/support.module').then(m => m.SupportModule),
    data: {
      breadcrumbLabel: 'Support'
    }
  }, {
    path: 'search',
    loadChildren: () => import('./search/search.module').then(m => m.SearchModule),
    data: {
      breadcrumbLabel: 'Search'
    }
  }, {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    data: {
      breadcrumbLabel: 'Dashboard',
      namespace: ['dashboard']
    },
    resolve: {
      localeResolver: localeResolver
    }
  }, {
    path: 'yourcause-admin',
    loadChildren: () => import('./yourcause-admin/yourcause-admin.module').then(m => m.YourcauseAdminModule),
    data: {
      namespace: ['nonprofits', 'claim', 'compliance', 'ofac']
    },
    resolve: {
      unsetActiveNpo: unsetActiveNpoResolver,
      localeResolver: localeResolver
    }
  }, {
    path: 'help-support',
    loadChildren: () => import('./help-support/help-support.module').then(m => m.HelpSupportModule),
    resolve: {
      customAlerts: customAlertsResolver
    },
    data: {
      breadcrumbLabel: 'Help and support',
      namespace: 'help-support'
    }
  }, {
    path: 'platform-admin',
    loadChildren: () => import('./platform-admin/platform-admin.module').then(m => m.PlatformAdminModule),
    data: {
      breadcrumbLabel: 'Platform Admin',
      namespace: 'platform-admin'
    }
  }, {
    path: 'nonprofit-management',
    loadChildren: () => import('./nonprofit-management/nonprofit-management.module').then(m => m.NonprofitManagementModule),
    data: {
      breadcrumbLabel: 'Nonprofit Management',
      namespace: 'nonprofit-management'
    },
    resolve: {
      allReasonsLookup: allReasonsResolver,
      allStatusesLookup: allStatusesResolver,
      registrationAuthoritiesForNonprofitAdd: registrationAuthoritiesForNonprofitAddResolver
    }
  }, {
    path: 'reporting',
    loadChildren: () => import('./reporting/reporting.module').then(m => m.ReportingModule),
    data: {
      breadcrumbLabel: 'Reporting',
      namespace: 'reporting'
    },
    resolve: {
      allReasonsLookup: allReasonsResolver,
      allStatusesLookup: allStatusesResolver
    }
  }, {
    path: 'compliance-admin',
    loadChildren: () => import('./compliance-admin/compliance-admin.module').then(m => m.ComplianceAdminModule),
    data: {
      breadcrumbLabel: 'Compliance'
    },
    resolve: {
      allReasonsLookup: allReasonsResolver
    }
  }, {
    path: 'system-health-dashboard',
    loadChildren: () => import('./system-health-dashboard/system-health-dashboard.module').then(m => m.SystemHealthDashboardModule),
    component: SystemHealthDashboardPageComponent,
    data: {
      breadcrumbLabel: 'System Health Dashboard'
    }
  }, {
    path: 'submitted-support-requests',
    loadChildren: () => import('./submitted-support-requests/submitted-support-requests.module').then(m => m.SubmittedSupportRequestsModule),
    data: {
      breadcrumbLabel: 'Support Requests'
    },
    resolve: {
      SupportRequestStatuses: SupportRequestStatusesResolver
    }
  }]
}];
