import { Injectable } from '@angular/core';
import { ContactPayload, CreateEventDocumentPayload, CreateEventPhotoPayload, CreateOrUpdateEventShiftPayload, DeleteEventDocumentPayload, DeleteEventPhotoPayload, EmailParticipantModalResponse, EventCategorySkillType, EventContact, EventSkillType, Participant, ParticipantExportModel, ParticipantResponse, VolunteerCommunityPartner, VolunteerEvent, VolunteerEventDocument, VolunteerEventPhoto, VolunteerShift, VolunteerTimeZone } from '@core/models/volunteer-event.model';
import { ExtractFilterColumnsService } from '@core/services/extract-filter-columns.service';
import { APIResponse, APIResult, PaginationOptions } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { NotifierService } from '@yourcause/common/notifier';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { AssetManagementService } from '../../asset-management/services/asset-management.service';
import { VolunteerEventResources } from './volunteer-event.resources';
import { VolunteerEventState } from './volunteer-event.state';
import { SelectOption } from '@yourcause/common/core-forms';
import { DateService } from '@yourcause/common/date';
import { FileService, YcFile } from '@yourcause/common/files';

@AttachYCState(VolunteerEventState)
@Injectable({ providedIn: 'root' })
export class VolunteerEventService extends BaseYCService<VolunteerEventState> {
  errorRetrievingDataText = this.i18n.translate(
    'common:notificationErrorRetreivingData',
    {},
    'There was an error retrieving the data'
  );
  errorDownloadingDataText = this.i18n.translate(
    'common:notificationErrorDownloadingData',
    {},
    'There was an error downloading the data'
  );
  constructor (
    private volunteerEventResources: VolunteerEventResources,
    private notifier: NotifierService,
    private i18n: I18nService,
    private extractColumnsService: ExtractFilterColumnsService,
    private fileService: FileService,
    private dateService: DateService,
    private assetManagementService: AssetManagementService
  ) {
    super();
  }

  // GET

  get activeEventId (): number {
    return this.get('activeEventId');
  }

  get activeVolunteerEvent (): VolunteerEvent {
    return this.get('activeVolunteerEvent');
  }

  get activeEventParticipants (): Participant[] {
    return this.get('activeEventParticipants');
  }

  get activeEventPhotos (): VolunteerEventPhoto[] {
    return this.get('activeEventPhotos');
  }

  get activeEventShifts (): VolunteerShift[] {
    return this.get('activeEventShifts');
  }

  get activeEventDocuments (): VolunteerEventDocument[] {
    return this.get('activeEventDocuments');
  }

  get volunteerTimeZones (): VolunteerTimeZone[] {
    return this.get('volunteerTimeZones');
  }

  get eventForCopy (): VolunteerEvent {
    return this.get('eventForCopy');
  }

  get communityPartners (): VolunteerCommunityPartner[] {
    return this.get('communityPartners');
  }

  get eventTimeLineColumnValue (): number {
    return this.get('eventTimeLineColumnValue');
  }

  get eventAffiliateId (): number {
    return this.get('eventAffiliateId');
  }

  get eventSkills (): SelectOption[] {
    return this.get('eventSkills');
  }

  get eventAffiliateSkills (): SelectOption[] {
    return this.get('eventAffiliateSkills');
  }

  get allSkillsMetaData (): EventSkillType[] {
    return this.get('allSkillsMetaData');
  }

  get skillsCategoriesMetaData (): EventCategorySkillType[] {
    return this.get('skillsCategoriesMetaData');
  }

  // SET

  async setActiveEventId (eventId: number) {
    this.set('activeEventId', eventId);
    await this.setActiveVolunteerEventById(eventId);
  }

  setActiveVolunteerEvent (event: VolunteerEvent) {
    this.set('activeVolunteerEvent', event);
  }

  async setActiveVolunteerEventById (eventId: number) {
    const event = await this.getEvent(eventId);
    this.set('activeVolunteerEvent', event);
  }

  async setActiveEventParticipants (participants: Participant[]) {
    this.set('activeEventParticipants', participants);
  }

  async setActiveEventPhotos (
    apiResponse: APIResponse<VolunteerEventPhoto[]>
  ) {
    this.set('activeEventPhotos', apiResponse.data);
  }

  setActiveEventShifts (shifts: VolunteerShift[]) {
    this.set('activeEventShifts', shifts);
  }

  async setActiveEventDocuments (docs: VolunteerEventDocument[]) {
    this.set('activeEventDocuments', docs);
  }

  async setVolunteerTimeZones () { // only in resolver
    const timeZones = await this.getTimeZones();
    this.set('volunteerTimeZones', timeZones.data);
  }

  async setEventForCopy (event: VolunteerEvent) {
    this.set('eventForCopy', event);
  }

  async setCommunityPartners (partners: VolunteerCommunityPartner[]) {
    this.set('communityPartners', partners);
  }

  setEventTimeLineColumnValue (val: number) {
    this.set('eventTimeLineColumnValue', val);
  }

  setEventAffiliateId (affiliateId: number) {
    this.set('eventAffiliateId', affiliateId);
  }

  async setEventSkills () {
    const response = await this.getAllEventSkills();
    const skills = response.data;
    const mappedSkills: SelectOption[] = skills.map((cat) => {
      return {
        label: cat.categoryName,
        display: cat.categoryName,
        value: cat.categoryId,
        dependentOptions: cat.categorySkills.map((opt) => {
          return {
            label: opt.skillName,
            display: opt.skillName,
            value: opt.skillId
          };
        })
      };
    });
    const mappedCategories = this.mapCategories(response.data);
    this.set('skillsCategoriesMetaData', mappedCategories);
    this.set('allSkillsMetaData', skills); //set raw, unmapped, skills data
    this.set('eventSkills', mappedSkills);
  }

  mapCategories (skills: EventSkillType[]) {
    const categoriesArray = skills.map((skill) => {
      return skill.categorySkills;
    });
    const flatArray = [].concat(...categoriesArray);

    return flatArray;
  }

  async setAffiliateSkills (eventAffiliateId: number) {
    const response = await this.getAffiliateEventSkills(eventAffiliateId);
    const skills = response.data;
    const mappedSkills: SelectOption[] = skills.map((cat) => {
      return {
        label: cat.categoryName,
        display: cat.categoryName,
        value: cat.categoryId,
        dependentOptions: cat.categorySkills.map((opt) => {
          return {
            label: opt.skillName,
            display: opt.skillName,
            value: opt.skillId
          };
        })
      };
    });
    const mappedCategories = this.mapCategories(response.data);
    this.set('skillsCategoriesMetaData', mappedCategories);
    this.set('eventAffiliateSkills', mappedSkills);
  }

  async adaptAndCreateEvent (payload: Partial<VolunteerEvent>) {
    let imageUrl;
    let createEventPayload;
    if (payload.image) {
      const file = new YcFile(
        '' + (Math.random() * 1000000000),
        payload.image
      );

      const uploadResponse = await this.uploadEventFile(file);
      imageUrl = uploadResponse.data;
      createEventPayload = {
        ...payload,
        imageUrl
      };
    } else {
      createEventPayload = payload;
    }
    const response = await this.createEvent(createEventPayload);
    if (!!response) {
      this.setActiveVolunteerEvent(response);
    }

    return response;
  }

  async adaptAndUpdateEvent (payload: Partial<VolunteerEvent>) {
    // check to make sure current image isn't being re-uploaded
    let imageUrl;
    if (payload.image && (typeof(payload.image) !== 'string')) {
      const file = new YcFile(
        '' + (Math.random() * 1000000000),
        payload.image
      );
      const uploadResponse = await this.uploadEventFile(file);
      imageUrl = uploadResponse.data;
    }
    delete payload.image;
    // some of the below fields may not be needed, this was for debugging
    const editEventPayload = {
      ...payload,
      imageUrl: imageUrl || payload.imageUrl,
      startDate: this.dateService.formatDate(new Date(payload.startDate), 'MM/dd/yyyy'),
      endDate: this.dateService.formatDate(new Date(payload.endDate), 'MM/dd/yyyy')
    };
    const response = await this.updateEvent(editEventPayload);
    if (response?.data) {
      await this.setActiveEventId(response.data.eventId);

      return response.data;
    } else {
      return null;
    }
  }

  adaptEventsListEndpoint () {
    const eventTimeLine = this.eventTimeLineColumnValue;
    let endpoint: string;
    switch (eventTimeLine) {
      case 0:
      default:
        endpoint = 'api/Volunteer/GetEvents';
        break;
      case 1:
        endpoint = 'api/Volunteer/GetInProgressEvents';
        break;
      case 2:
        endpoint = 'api/Volunteer/GetPastEvents';
        break;
      case 3:
        endpoint = 'api/Volunteer/GetUpcomingEvents';
        break;
    }

    return endpoint;
  }

  convertParticipantExport (object: APIResponse<ParticipantExportModel>) {
    const response = this.fileService.convertObjectArrayToCSVString(
      object.data.participants
    );

    return response;
  }


  // APIs

  async getContacts (
    eventId: number,
    paginationOptions: PaginationOptions<EventContact>
  ): Promise<APIResult<EventContact>> {
    try {
      const contacts = await this.getEventContacts (
        eventId,
        paginationOptions
      );

      return {
        success: true,
        data: {
          recordCount: contacts.data.totalCount,
          records: contacts.data.contacts
        }
      };
    } catch (e) {
      throw e;
    }
  }

  async getEventsList (
    paginationOptions: PaginationOptions<VolunteerEvent>
  ): Promise<APIResult<VolunteerEvent>> {
    const endpoint = this.adaptEventsListEndpoint();
    const response = await this.fetchVolunteerEventsList(
      endpoint,
      paginationOptions
    );

    return {
      success: true,
      data: {
        recordCount: response?.data?.totalCount,
        records: response?.data?.events
      }
    };
  }

  async getEvent (eventId: number) {
    try {
      const response = await this.volunteerEventResources.getEvent(eventId);

      return response;
    } catch (e) {
      console.error(e);

      return null;
    }
  }

  async getParticipants (
    paginationOptions: PaginationOptions<Participant>
  ) {
    let response: any;
    let apiResponse: APIResponse<ParticipantResponse>;
    const shiftIdColumn = 'shiftId';
    const adaptedParams = this.extractColumnsService
      .extractFilterColumnsFromPaginationOptions(
        paginationOptions,
        [shiftIdColumn]
      );
    if (!!adaptedParams.params[shiftIdColumn]) {
      apiResponse = await this.getEventParticipationsByShift(
        this.activeEventId,
        adaptedParams.params[shiftIdColumn],
        adaptedParams.paginationOptions
      );
      response = {
        success: true,
        data: {
          recordCount: apiResponse.data.totalCount,
          records: apiResponse.data.participants
        }
      };
    } else {
      apiResponse = await this.getEventParticipants(
        this.activeEventId,
        paginationOptions
      );
      response = {
        success: true,
        data: {
          recordCount: apiResponse.data.totalCount,
          records: apiResponse.data.participants
        }
      };
    }

    return response;
  }

  async getParticipantsExport (
    paginationOptions: PaginationOptions<Participant>
  ) {
    let apiResponse: any;
    let response: any;
    const shiftIdColumn = 'shiftId';
    const adaptedParams = this.extractColumnsService
      .extractFilterColumnsFromPaginationOptions(
        paginationOptions,
        [shiftIdColumn]
      );
    if (!!adaptedParams.params[shiftIdColumn]) {
      apiResponse = await this.getEventParticipationsByShiftExport(
        this.activeEventId,
        adaptedParams.params[shiftIdColumn]
      );
      response = this.convertParticipantExport(apiResponse);
    } else {
      apiResponse = await this.getEventParticipationsExport(
        this.activeEventId
      );
      response = this.convertParticipantExport(apiResponse);
    }

    return response;
  }

  async getEventParticipants (
    eventId: number,
    paginationOptions: PaginationOptions<Participant>
  ) {
    try {
      const response = await this.volunteerEventResources.getEventParticipants(
        eventId,
        paginationOptions
      ) as APIResponse<ParticipantResponse>;

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'volunteer:notificationErrorFetchingParticipants',
        {},
        'There was an error fetching event participants'
      ));

      return null;
    }
  }

  async getEventParticipationsExport (
    eventId: number
  ) {
    try {
      const response = await this.volunteerEventResources.getEventParticipationsExport(
        eventId
      ) as APIResponse<ParticipantExportModel>;

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.errorDownloadingDataText);

      return null;
    }

  }

  async getEventParticipationsByShift (
    eventId: number,
    shiftId: number,
    paginationOptions: PaginationOptions<Participant>
  ) {
    try {
      const response = await this.volunteerEventResources.getEventParticipationsByShift(
        eventId,
        shiftId,
        paginationOptions
      );

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'volunteer:notificationErrorFetchingParticipants',
        {},
        'There was an error fetching event participants'
      ));

      return null;
    }
  }

  async getEventParticipationsByShiftExport (
    eventId: number,
    shiftId: number
  ) {
    try {
      const response = await this.volunteerEventResources.getEventParticipationsByShiftExport(
        eventId,
        shiftId
      );

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.errorDownloadingDataText);

      return null;
    }
  }

  async getWaitlist (
    paginationOptions: PaginationOptions<Participant>
  ) {
    let response: any;
    let apiResponse: APIResponse<ParticipantResponse>;
    const shiftIdColumn = 'shiftId';
    const adaptedParams = this.extractColumnsService
      .extractFilterColumnsFromPaginationOptions(
        paginationOptions,
        [shiftIdColumn]
      );
    if (!!adaptedParams.params[shiftIdColumn]) {
      apiResponse = await this.getEventWaitlistByShift(
        this.activeEventId,
        adaptedParams.params[shiftIdColumn],
        adaptedParams.paginationOptions
      );
      response = {
        success: true,
        data: {
          recordCount: apiResponse.data.totalCount,
          records: apiResponse.data.participants
        }
      };
    } else {
      apiResponse = await this.getEventWaitlist(
        this.activeEventId,
        paginationOptions
      );
      response = {
        success: true,
        data: {
          recordCount: apiResponse.data.totalCount,
          records: apiResponse.data.participants
        }
      };
    }

    return response;
  }

  async getWaitlistExport (
    paginationOptions: PaginationOptions<Participant>
  ) {
    let apiResponse: any;
    let response: any;
    const shiftIdColumn = 'shiftId';
    const adaptedParams = this.extractColumnsService
      .extractFilterColumnsFromPaginationOptions(
        paginationOptions,
        [shiftIdColumn]
      );
    if (!!adaptedParams.params[shiftIdColumn]) {
      apiResponse = await this.getEventWaitlistByShiftExport(
        this.activeEventId,
        adaptedParams.params[shiftIdColumn]
      );
      response = this.convertParticipantExport(apiResponse);
    } else {
      apiResponse = await this.getEventWaitlistExport(
        this.activeEventId
      );
      response = this.convertParticipantExport(apiResponse);
    }

    return response;
  }

  async getEventWaitlist (
    eventId: number,
    paginationOptions: PaginationOptions<Participant>
  ) {
    try {
      const response = await this.volunteerEventResources.getEventWaitlist(
        eventId,
        paginationOptions
      ) as APIResponse<ParticipantResponse>;

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'volunteer:notificationErrorFetchingParticipants',
        {},
        'There was an error fetching event participants'
      ));

      return null;
    }
  }

  async getEventWaitlistExport (
    eventId: number
  ) {
    try {
      const response = await this.volunteerEventResources.getEventWaitlistExport(
        eventId
      ) as APIResponse<ParticipantExportModel>;

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.errorDownloadingDataText);

      return null;
    }

  }

  async getEventWaitlistByShift (
    eventId: number,
    shiftId: number,
    paginationOptions: PaginationOptions<Participant>
  ) {
    try {
      const response = await this.volunteerEventResources.getEventWaitlistByShift(
        eventId,
        shiftId,
        paginationOptions
      );

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'volunteer:notificationErrorFetchingParticipants',
        {},
        'There was an error fetching event participants'
      ));

      return null;
    }
  }

  async getEventWaitlistByShiftExport (
    eventId: number,
    shiftId: number
  ) {
    try {
      const response = await this.volunteerEventResources.getEventWaitlistByShiftExport(
        eventId,
        shiftId
      );

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.errorDownloadingDataText);

      return null;
    }
  }

  async getEventPhotos (eventId: number) {
    try {
      const response = await this.volunteerEventResources.getEventPhotos(eventId);

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'volunteer:notificationErrorFetchingPhotos',
        {},
        'There was an error fetching photos this event, please try again'
      ));

      return null;
    }
  }

  async createEventPhoto (payload: CreateEventPhotoPayload) {
    try {
      const response = await this.volunteerEventResources.createEventPhoto(payload);
      this.notifier.success(this.i18n.translate(
        'volunteer:textUploadPhotoSuccess',
        {},
        'Successfully uploaded event photo'
      ));

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'volunteer:notificationPhotoUploadError',
        {},
        'There was an error uploading this photo, please try again'
      ));

      return null;
    }
  }

  async deleteEventPhoto (payload: DeleteEventPhotoPayload) {
    try {
      await this.volunteerEventResources.deleteEventPhoto(payload);
      this.notifier.success(this.i18n.translate(
        'volunteer:textRemovePhotoSuccess',
        {},
        'Successfully removed event photo'
      ));
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'volunteer:notificationDeletePhotoError',
        {},
        'There was an error removing this photo, please try again'
      ));
    }
  }

  async getEventShiftsPaginated (
    paginationOptions: PaginationOptions<VolunteerShift>
  ) {
    try {
      const response = await this.volunteerEventResources.getEventShiftsPaginated(
        this.activeEventId,
        paginationOptions
      );

      return {
        success: true,
        data: {
          recordCount: response.data.totalCount,
          records: response.data.shifts
        }
      };
    } catch (e) {
      console.error(e);

      return null;
    }
  }

  async getEventShiftsNoPagination (eventId: number) {
    try {
      const response = this.volunteerEventResources.getEventShiftsNoPagination(
        eventId
      );

      return response;
    } catch (e) {
      console.error(e);

      return null;
    }
  }

  async getEventContacts (
    eventId: number,
    paginationOptions: PaginationOptions<EventContact>
  ) {
    try {
      const response = await this.volunteerEventResources.getEventContacts(
        eventId,
        paginationOptions
      );

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'volunteer:notificationErrorFetchingContacts',
        {},
        'There was an error fetching contacts'
      ));

      return null;
    }
  }

  async getEventDocuments (eventId: number) {
    try {
      const response = await this.volunteerEventResources.getEventDocuments(eventId);

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'volunteer:notificationErrorFetchingDocuments',
        {},
        'There was an error fetching documents this event, please try again'
      ));

      return null;
    }
  }

  async createEventDocument (payload: CreateEventDocumentPayload) {
    try {
      await this.volunteerEventResources.createEventDocument(payload);
      this.notifier.success(this.i18n.translate(
        'volunteer:textUploadDocumentSuccess',
        {},
        'Successfully uploaded event document'
      ));
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'volunteer:notificationDocumentUploadError',
        {},
        'There was an error uploading this document, please try again'
      ));
    }
  }

  async deleteEventDocument (payload: DeleteEventDocumentPayload) {
    try {
      await this.volunteerEventResources.deleteEventDocument(payload);
      this.notifier.success(this.i18n.translate(
        'volunteer:textRemoveDocumentSuccess',
        {},
        'Successfully removed event document'
      ));

    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'volunteer:notificationDeleteDocumentError',
        {},
        'There was an error removing this document, please try again'
      ));
    }

  }

  async getTimeZones () {
    try {
      const response = await this.volunteerEventResources.getTimeZones();

      return response;
    } catch (e) {
      console.error(e);

      return null;
    }
  }

  async fetchVolunteerEventsList (
    endpoint: string,
    paginationOptions: PaginationOptions<VolunteerEvent>
  ) {
    try {
      const response = await this.volunteerEventResources.fetchVolunteerEventsList(
        endpoint,
        paginationOptions
      );

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.errorRetrievingDataText);

      return null;
    }
  }

  async getEventCommunityPartners () {
    try {
      const response = await this.volunteerEventResources.getEventCommunityPartners();

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'volunteer:notificationErrorFetchingCommunityPartners',
        {},
        'There was an error fetching community partners, please try again'
      ));

      return null;
    }
  }

  async createEvent (payload: Partial<VolunteerEvent>) {
    try {
      const response = await this.volunteerEventResources.createEvent(
        payload
      );
      this.notifier.success(this.i18n.translate(
        'volunteer:textCreateEventSuccessful',
        {},
        'Successfully created event'
      ));

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'volunteer:notificationCreateEventError',
        {},
        'There was an error creating this event, please try again'
      ));

      return null;
    }
  }

  async updateEvent (
    payload: Partial<VolunteerEvent>
  ): Promise<APIResponse<VolunteerEvent>> {
    try {
      const response = await this.volunteerEventResources.updateEvent(payload);
      this.notifier.success(this.i18n.translate(
        'volunteer:notificationUpdateEventSuccess',
        {},
        'Successfully updated event'
      ));

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'volunteer:notificationUpdateEventError',
        {},
        'There was an error updating this event, please try again'
      ));

      return null;
    }
  }

  async deactivateEvent (
    eventId: number,
    isDraft: boolean
  ) {
    try {
      const response = await this.volunteerEventResources.deactivateEvent(eventId);
      if (isDraft) {
        this.notifier.success(this.i18n.translate(
          'volunteer:notificationDeleteEventSuccess',
          {},
          'Successfully deleted event draft'
        ));
      } else {
        this.notifier.success(this.i18n.translate(
          'volunteer:notificationDeactivateEventSuccess',
          {},
          'Successfully deactivated event'
        ));
      }

      return response;
    } catch (e) {
      console.error(e);
      if (isDraft) {
        this.notifier.error(this.i18n.translate(
          'volunteer:notificationDeleteEventError',
          {},
          'There was an error deleting this event, please try again'
        ));
      } else {
        this.notifier.error(this.i18n.translate(
          'volunteer:notificationDeactivateEventError',
          {},
          'There was an error deactivating this event, please try again'
        ));
      }

      return null;
    }
  }

  async publishEvent (
    eventId: number,
    requiresApproval?: boolean
  ) {
    try {
      await this.volunteerEventResources.publishEvent(
        eventId,
        requiresApproval
      );
      if (!requiresApproval) {
        this.notifier.success(this.i18n.translate(
          'volunteer:notificationPublishEventSuccess',
          {},
          'Successfully published event'
        ));
      } else {
        this.notifier.success(this.i18n.translate(
          'volunteer:notificationSubmitEventSuccess',
          {},
          'Successfully submitted event'
        ));
      }
    } catch (e) {
      console.error(e);
      if (!requiresApproval) {
        this.notifier.error(this.i18n.translate(
          'volunteer:notificationPublishingEventError',
          {},
          'There was an error publishing this event, please try again'
        ));
      } else {
        this.notifier.error(this.i18n.translate(
          'volunteer:notificationSubmitEventError',
          {},
          'There was an error submitting this event, please try again'
        ));
      }
    }
  }

  async emailEventParticipants (
    payload: EmailParticipantModalResponse
  ) {
    try {
      await this.volunteerEventResources.emailEventParticipants(payload);
      this.notifier.success(this.i18n.translate(
        'common:notificaitonEmailSentSuccessfully',
        {},
        'Successfully sent email'
      ));
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'common:notificationThereWasAnErrorSendingTheMessage',
        {},
        'There was an error sending the email'
      ));
    }
  }

  async deleteEventShift (
    eventId: number,
    shiftId: number
  ) {
    try {
      await this.volunteerEventResources.deleteEventShift(
        eventId,
        shiftId
      );
      this.notifier.success(this.i18n.translate(
        'volunteer:textRemovedShift',
        {},
        'Successfully removed shift'
      ));
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'volunteer:textErrorRemovingShift',
        {},
        'Error removing shift, please try again later'
      ));
    }
  }

  async createOrUpdateEventShift (
    payload: CreateOrUpdateEventShiftPayload
  ) {
    try {
      const response = await this.volunteerEventResources.createOrUpdateEventShift(
        payload
      );
      this.notifier.success(this.i18n.translate(
        'volunteer:textUpdatedShifts',
        {},
        'Successfully updated shifts'
      ));

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'volunteer:textErrorUpdattingShifts',
        {},
        'Error updating shifts'
      ));

      return null;
    }
  }

  async createOrUpdateEventContact (payload: ContactPayload) {
    try {
      const response = await this.volunteerEventResources.createOrUpdateEventContact(
        payload
      );
      this.notifier.success(this.i18n.translate(
        'volunteer:textUpdatedContacts',
        {},
        'Successfully updated contacts'
      ));

      return response;
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'volunteer:textErrorUpdattingContacts',
        {},
        'Error updating contacts'
      ));

      return null;
    }
  }

  async deleteEventContact (
    eventId: number,
    contactId: number
  ) {
    try {
      await this.volunteerEventResources.deleteEventContact(
        eventId,
        contactId
      );
      this.notifier.success(this.i18n.translate(
        'volunteer:notficationRemovedContact',
        {},
        'Successfully removed contact'
      ));
    } catch (e) {
      console.error(e);
      this.notifier.error(this.i18n.translate(
        'volunteer:notificationErrorRemovingContact',
        {},
        'There was an error removing this contact'
      ));
    }
  }

  async uploadEventFile (file: YcFile) {
    const response = await this.assetManagementService.uploadVolunteerEventFile(file);

    return response;
  }

  async getAllEventSkills () {
    const response = await this.volunteerEventResources.getAllEventSkills();

    return response;
  }

  async getAffiliateEventSkills (affiliateId: number) {
    const response = await this.volunteerEventResources.getAffiliateEventSkills(affiliateId);

    return response;
  }
}
