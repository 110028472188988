import { Address, ClassificationInfo, PaginationOptions, RemittanceInfoModel, StandardAddressNppModel } from '@yourcause/common';
import { Transaction } from './donation.model';
import { NonprofitBlockDisplayModel } from './npo.model';
import { User } from './user.model';
import { VettingTypeName } from '@core/typings/vetting-application.typing';

export interface CharityAdminDetailDownloadInfo {
  url: string;
  fileName: string;
}

/**
 * RA/VA Import History
 */
export interface ImportHistoryModel {
  dataImportTypeId: number;
  name: string;
  dataImportTypeName: string;
  processScheduleType: string;
  lastProcessedDate: string;
}

/**
 * RA/VA Import History Summary
 */
export interface ImportSummaryModel {
  importBatchId: number;
  importDate: string;
  numberNposAdded: number;
  numberNposRemoved: number;
  totalActiveNpos: number;
  numberNposVetted: number;
  numberNposUnvetted: number;
}

export interface ComplianceRecord {
  nonprofitId: number;
  registrationAuthority: string;
  regId: string;
  ycCharityId: number;
  charityName: string;
  ach: boolean;
  federalUSCompliance: string; // PART I
  federalUSComplianceStatusDate?: string;
  federalUSComplianceExpirationDate?: string;
  federalUSComplianceInherited?: boolean;
  nonDiscriminationCompliance: string; // PART II
  nonDiscriminationComplianceStatusDate?: string;
  nonDiscriminationComplianceExpirationDate?: string;
  nonDiscriminationComplianceInherited?: boolean;
  nonReligiousCompliance: string; // PART III
  nonReligiousComplianceStatusDate?: string;
  nonReligiousComplianceExpirationDate?: string;
  nonReligiousComplianceInherited?: boolean;
  antiCorruptionAndBriberyCompliance: string; // PART IV
  antiCorruptionAndBriberyComplianceStatusDate?: string;
  antiCorruptionAndBriberyComplianceExpirationDate?: string;
  antiCorruptionAndBriberyComplianceInherited?: boolean;
  bbgfGrantTermsCompliance?: string; // PART V
  bbgfGrantTermsComplianceStatusDate?: string;
  bbgfGrantTermsComplianceExpirationDate?: string;
  bbgfGrantTermsComplianceInherited?: boolean;
  fraternal: boolean;
  political: boolean;
  religious: boolean;
  sectarian: boolean;
  service: boolean;
  veterans: boolean;
  noneOfTheAbove: boolean;
  companyCompliance: string;
  companyComplianceStatusDate?: string;
  companyComplianceExpirationDate?: string;
  companyComplianceInherited?: boolean;
  mostEngagedAdminEmail: string;
}

export interface JobsReportModel {
  jobId: number;
  jobName: string;
  jobDesc: string;
  interval: number;
  frequency: string;
  dayOfWeek: string;
  avgRunMinutes: string;
  lastExecutedDate: string;
  success: boolean;
}

export interface CustomComplianceCompany {
  name: string;
  complianceId?: number;
  companyId: number;
}

export interface NpoForChapterReport {
  createDate: string;
  createdByUserEmail: string;
  processesOwnFunds: boolean;
  isActive: boolean;
  hasAchInfo: boolean;
  remittanceInfo: RemittanceInfoModel;
  registrationAuthorityId: number;
  nonprofitIconImageUrl: string;
  id: number;
  registrationAuthorityName: string;
  registrationId: string;
  name: string;
  ycCharityId: number;
  address: StandardAddressNppModel;
}

export interface ChapterReportModel extends NpoForChapterReport {
  parentNonprofit: NpoForChapterReport;
}

export interface ChapterReportModelForDownload {
  chapterName: string;
  chapterRegistrationAuthority: string;
  chapterRegistrationId: string;
  chapterYCCharityId: string;
  chapterDisplayAddress: StandardAddressNppModel;
  chapterCreateDate: string;
  chapterCreatedBy: User;
  chapterEligibleForGiving: string;
  chapterIsActive: boolean;
  parentName: string;
  parentRegistrationAuthority: string;
  parentRegistrationId: string;
  parentYCCharityId: string;
  parentRegisteredAddress: StandardAddressNppModel;
  parentDisplayAddress: StandardAddressNppModel;
  parentCreateDate: string;
  parentCreatedBy: User;
  parentHasACH: boolean;
  parentRemittanceInfo: RemittanceInfoModel;
  parentEligibleForGiving: string;
}


export interface NonprofitClassificationInfo extends ClassificationInfo {
  classificationName?: string;
}

export interface OutstandingPaymentModel {
  charityName: string;
  charityID: number;
  nonprofitId: number;
  einNumber: string;
  isNPOActive: boolean;
  hasAch: boolean;
  achRegistrationDate: string;
  npoAdminMemberID: number;
  npoAdminFirstName: string;
  npoAdminLastName: string;
  npoAdminEmail: string;
  hasActiveAdmin: boolean;
  address: StandardAddressReportingDownloadModel;
  remittanceInfo: RemittanceInfoModel;
  nonprofitIconImageUrl: string;
  clientName: string;
  paymentId: number;
  checkNumber: number;
  checkAmount: number;
  checkCreatedDate: string;
  checkPaymentStatus: string;
  checkStatusDate: string;
  reissueRequest: boolean;
  charityAdminLastLoginDate: string;
  dateLastCashed: string;
}

export interface TransactionDetail extends Transaction {
  // fields not available from CSR yet
  payrollPledgeId?: number;
  originalDonationId?: number;
  isEmpActive?: boolean;
}

export interface InactiveNpoWithPendingDisbursementModel {
  name: string;
  nonprofitId: number;
  address?: Address;
  registrationId: string;
  nonprofitIconImageUrl: string;
  ycCharityId: number;
  registrationAuthorityId: number;
  registrationAuthorityName?: string;
  totalNumberOfDonations: number;
  totalAmountPending: number;
}

export interface MergedNonprofitsReportModel {
  masterNonprofitId: number;
  masterNonprofitYcCharityId: string;
  masterNonprofit: NonprofitBlockDisplayModel;
  mergedNonprofitId: number;
  mergedNonprofitYcCharityId: string;
  mergedNonprofit: NonprofitBlockDisplayModel;
  mergedByUserId: number;
  mergedByUserName: string;
  reasonForMerge: string;
  dateMerged: string;
}

export interface StandardAddressReportingDownloadModel {
  address1: string;
  address2: string;
  city: string;
  stateProvRegCode: string;
  countryCode: string;
  postalCode: string;
}

export interface NpoOnExclusionList {
  npoRegAuthorityId: number;
  npoRegAuthorityName: string;
  npoRegistrationId: string;
  name: string;
  dateAdded: string;
  reason: string;
  reasonId: number;
  comments: string;
  ycCharityId: number;
}

export interface NpoWithDisbursementSuspended {
  nonprofit: NonprofitBlockDisplayModel;
  flagUpdatedDate: string;
  reasonId: number;
  reason: string;
  comment?: string;
  nonprofitAdmin?: User;
  disbursementAmountOutstanding?: number;
  eligibleForGivingStatus: string;
}

export interface NpoWithDisbursementSuspendedDownload {
  nonprofit: NonprofitBlockDisplayModel;
  flagUpdatedDate: string;
  reasonId: number;
  reason: string;
  comment?: string;
  disbursementAmountOutstanding?: number;
  eligibleForGivingStatus: string;
  activeAdminEmail: string;
  activeAdminFirstName: string;
  activeAdminLastName: string;
}

export interface ActiveNonprofitsByEFG {
  registrationAuthorityName?: string;
  countryName?: string;
  processingPartner?: string;
  totalActive: number;
  totalEligibleForGiving: number;
  totalNeedsVetting: number;
  totalNotEligibleForGiving: number;
}

export enum ComplianceReportTypeStatus {
  Approved = 'Approved',
  Expired = 'Expired',
  Declined = 'Declined',
  NotResponded = 'Not Responded',
  NA = 'N/A'
}

export interface ComplianceAndAffirmationCSV {
  companyName: string;
  text: string;
}

export interface EmailLogReportModel {
  id: number;
  emailSentOn: string;
  toEmail: string;
  fromEmail: string;
  subject: string;
  toName: string;
  success: boolean;
  nonprofitName: string;
  nonprofitId: number;
  deliveryStatus: string;
  deliveryStatusType: string;
  deliveryStatusSubType: string;
  deliveryStatusDate: string;
}

export interface VettingRequestForReportingModel {
  vettingRequestId: number; // not used in download endpoint
  ycVettingRequestId: number;
  externalSystemTypeId?: number; // not used in download endpoint
  externalSystemType: string; // not used in download endpoint
  nonprofit: NonprofitBlockDisplayModel;
  client: string;
  affiliateEmployeeId: number;
  affiliateEmployeeFirstName: string;
  affiliateEmployeeLastName: string;
  affiliateEmployeeEmail: string;
  nonprofitContactName: string;
  nonprofitContactEmail: string;
  nonprofitContactWebsite: string;
  requestDateSubmitted: string;
  requestStatus: string;
  requestStatusChangeDate: string;
  requestStatusChangedBy: User;
  requestStatusReason: string;
  requestStatusComment: string;
  applicationSubmittedDate: string;
  requestStatusId?: number; // not used in download endpoint
  requestStatusReasonId?: number; // not used in download endpoint
  vettingExpirationDate: string;
  vettingInvitationsSent: number; // Number of invitations to start vetting application sent out of 3 total table nonprofitvettingrequest - Additional Info popup
  lastVettingInvitationSentOn: string; // - date - nonprofitvettingrequest - Additional Info popup
  applicationId: number; // - int - Additional Info popup
  applicationCreateDate: string; //- date - Additional Info popup
  applicationCreatedBy: User; // - user model - Additional Info popup
  applicationStatusId: number; // - int - do not show and exclude from download
  applicationStatusReason: string; // (name) - Additional Info popup
  applicationStatusReasonId: number; // - int - do not show and exclude from download
  applicationStatusChangeDate: string; // - date -surface on table
  applicationStatusChangedBy: User;
  applicationStatusComment: string; // - string - Additional Info popup
  gcWorkflowName: string; // - This is from NonprofitVettingApplication - Additional Info popup
  daysInGCWorkflowLevel: string; // - This is from NonprofitVettingApplication - Additional Info popup
  reminderEmailsSentCount: number; // - int - This is from NonprofitVettingApplication - Additional Info popup
  applicationStatus: string; // (name) - surface on table
  gcApplicationId: number;
  numberOfRelatedVettingRequests: number;
}

export interface BankCustomAttributesReportReturnModel {
  country: string;
  attributeOrder: number;
  attributeName: string;
  min: number;
  max: number;
  attributeDescription: string;
  attributeExample: string;
  isRequired: boolean;
  createDate: string;
}

export interface BbiNonprofitSubsectorReportModel {
  nonprofitBlockDisplay: NonprofitBlockDisplayModel;
  nteeCode: string;
  nteeName: string;
  bbiSubsectorCode: string;
  bbiSubsectorName: string;
  sumOfDisbursedPayments: number;
}

export interface FailedPaymentReturnedChecksReportDisplayReturnModel {
  bankingDetailsLastUpdated: string;
  clientName: string;
  disbursementAddressLastUpdated: string;
  failedPaymentDate: string;
  failedPaymentNumber: string;
  isEligibleForDisbursement: boolean;
  nonprofit: NonprofitBlockDisplayModel;
  totalOutstandingPaymentAmount: number;
}

export interface BbiSubsectorFromApi {
  bbiSubsectorCode: string;
  bbiSubsectorName: string;
}

export interface RevokedNonprofitsSubdivisionsDto {
  revokedNonprofitsSubdivisions: string[];
}

export interface BbiNonprofitSubsectorReportPayload {
  earliestPaymentDate: string;
  latestPaymentDate: string;
  paginationOptions: PaginationOptions<BbiNonprofitSubsectorReportModel>;
}

export interface NonprofitClassificationHierarchyRequestModel {
  registrationAuthority: string;
  countryNonprofitClassificationId: string;
  countryNonprofitClassificationDescription: string;
  mappedToNTEECode: string;
  mappedToNTEEDescription: string;
}

export interface YcCharityIdRecord {
  ycCharityId: number;
}

export interface VettingApplicationsSubmittedOrCompletedReportDisplayModel {
  applicationId: number;
  gcVettingApplicationId: number;
  applicationCreateDate: string;
  applicationSubmittedDate: string;
  applicationSubmittedById: number;
  applicationSubmittedByFirstName: string;
  applicationSubmittedByLastName: string;
  applicationSubmittedByEmail: string;
  applicationSubmittedByFullName: string;
  applicationSubmittedByLastLoginDate: string;
  applicationWFL: string;
  numberOfDaysInWorkflow: number;
  numberOfRelatedVettingRequests: number;
  vettingExpirationDate: string;
  renewal: boolean;
  statusId: number;
  statusChangeDate: string;
  statusChangedById: number;
  statusChangedByFirstName: string;
  statusChangedByLastName: string;
  statusChangedByEmail: string;
  statusChangedByFullName: string;
  statusChangedByLastLoginDate: string;
  statusReasonId: number;
  statusReason: string;
  statusComment: string;
  vettingType: VettingTypeName;
  nonprofitRegistrationAuthorityId: number;
  nonprofitIconImageUrl: string;
  nonprofitEligibleForGivingStatusId: number;
  nonprofitIsActive: boolean;
  nonprofitAddress1: string;
  nonprofitCity: string;
  nonprofitStateProvRegCode: string;
  nonprofitStateProvRegId: number;
  nonprofitPostalCode: string;
  nonprofitCountryCode: string;
  nonprofitId: number;
  nonprofitRegistrationAuthorityName: string;
  nonprofitRegistrationId: string;
  nonprofitName: string;
}

export interface ReportsAndReportTypesRequestModel {
  id: number;
  name: string;
  nameKey: string;
  description: string;
  descriptionKey: string;
  downloadOnly: boolean;
  url: string;
  reportType: ReportType;
}

export interface ReportType {
  id: number;
  name: string;
  nameKey: string;
  description: string;
  descriptionKey: string;
}

export interface ActiveNpoByEFGLinkPayload {
  registrationAuthorityName: string;
  countryName: string;
  processingPartner?: string;
  efgTotalType: ActiveNpoByEFGTotalType;
}

export interface ApplicationDataForMoreInfoModal {
  applicationId: number;
  gcVettingApplicationId: number;
  applicationCreateDate: string;
  applicationSubmittedDate: string;
  applicationSubmittedByFullName: string;
  applicationSubmittedByEmail: string;
  applicationSubmittedByLastLoginDate: string;
  applicationWFL: string;
  gCWorkflowName: string;
  numberOfDaysInWorkflow: number;
  daysInGCWorkflowLevel: string;
  numberOfRelatedVettingRequests: number;
  reminderEmailsSentCount: number;
  applicationStatusChangeDate: string;
  applicationStatusChangedBy: User;
  statusReason: string;
  statusComment: string;
  renewal: boolean;
  nonprofitName: string;
  statusChangedByEmail: string;
  applicationStatus: string;
}

export interface RequestDataForMoreInfoModal {
  vettingRequestId: number;
  ycVettingRequestId: number;
  requestDateSubmitted: string;
  externalSystemType: string;
  client: string;
  affiliateEmployeeFirstName: string;
  affiliateEmployeeLastName: string;
  affiliateEmployeeEmail: string;
  vettingInvitationsSent: number; // Number of invitations to start vetting application sent out of 3 total table nonprofitvettingrequest
  lastVettingInvitationSentOn: string; // nonprofitvettingrequest - Additional Info popup
  requestStatus: string;
  requestStatusChangeDate: string;
  requestStatusChangedBy: User;
  requestStatusReason: string;
  requestStatusComment: string;
  nonprofitName: string;
}

export interface NonprofitEligibileForGivingOverridesDto {
  nonprofit: NonprofitBlockDisplayModel;
  efgOverrideDate: string;
  efgOverrideComment: string;
  efgOverrideBy: User;
}

export enum EmailLogSearchType {
  EMAIL_ADDRESS = 'emailAddress',
  NONPROFIT_NAME = 'nonprofitName'
}

export enum FailedPaymentSearchType {
  NONPROFIT_NAME = 'nonprofit.name',
  REGISTRATION_ID = 'nonprofit.registrationId',
  YC_CHARITY_ID = 'nonprofit.ycCharityId',
  PAYMENT_NUMBER = 'failedPaymentNumber',
  PAYMENT_DATE = 'failedPaymentDate'
}

export const REPORT_TYPE_MAP = {
  'Nonprofit Administration': 1,
  Communication: 2,
  Compliance: 3,
  Finance: 4,
  Vetting: 5
};

export enum ActiveNpoByEFGTotalType {
  TOTAL_ACTIVE = 1,
  TOTAL_EFG = 2,
  TOTAL_NEEDS_VETTING = 3,
  TOTAL_NOT_EFG = 4
}

export enum ActiveNpoByEFGDownloadTab {
  REG_AUTH_AND_COUNTRY = 1,
  COUNTRY = 2,
  REG_AUTH = 3,
  PROCESSING_PARTNER = 4
}

export enum VR_REPORT_STATUS_OPTION {
  ALL_REQUESTS,
  ALL_ACTIVE_REQUESTS
}

export enum AdditionalInfoModalType {
  REQUEST,
  APPLICATION
}

export interface RevokedNonprofitDto {
  registrationAuthorityName: string;

  nonprofitId?: number;
  nonprofitRegistrationId: string;
  nonprofitYCCharityId?: number;
  nonprofitName: string;
  nonprofitAddress: Address;
  nonprofitIconImageUrl: string;

  revocationListId: number;
  revocationListName: string;
  revocationStatus: string;
  revocationStatusDate: string;
  matchMethod: string;
  revocationFileId: string;
  revocationFileName: string;
  revocationFileRegistrationId: string;
  revocationCity: string;
}
