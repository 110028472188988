import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserLoginClaims } from '@core/models/account.model';
import { BlackbaudSsoError, SSOToken } from '@core/models/blackbaud-sso.model';
import { AccountResources } from '@core/resources/account.resources';
import { TokenState } from '@core/state/token.state';
import { TokenContent, TokenResponse } from '@core/typings/token.typing';
import { environment } from '@environment';
import { ModalFactory } from '@yourcause/common/modals';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { AppInsightsService } from '@yourcause/common/utils';
import { NotificationsService } from '../../../home/services/notifications.service';
import { AccountService } from '../account.service';
import { BBIDService } from '../bbid.service';
import { TokenRefreshResources } from './token-refresh.resources';
import { TokenRetrievalResources } from './token-retrieval.resources';
import { TokenStorageService } from './token-storage.service';
import { TokenTimeoutService } from './token-timeout.service';

@AttachYCState(TokenState)
@Injectable({ providedIn: 'root' })
export class TokenService extends BaseYCService<TokenState> {
  private permittedPlatformTransferRedirects = [
    'localhost:51849'
  ];

  private logoutTriggered = false;
  private latestProm: Promise<string|TokenResponse>;
  private refreshOffset = 1 /* minute(s) */ * 60 /* seconds */ * 1000 /* milliseconds */;

  constructor (
    private modalFactory: ModalFactory,
    private refresh: TokenRefreshResources,
    private retrieval: TokenRetrievalResources,
    private storage: TokenStorageService,
    private timeout: TokenTimeoutService,
    private appInsights: AppInsightsService,
    private accountService: AccountService,
    private notificationService: NotificationsService,
    private bbidService: BBIDService,
    private accountResources: AccountResources
  ) {
    super();

    if (!!this.hasToken()) {
      this.setTokenInfo(this.tokenInfo);
    }
  }

  get isBbid () {
    return this.storage.jwt?.bbidToken;
  }

  get userId () {
    return this.get('userId');
  }

  get userEmail () {
    return this.get('userEmail');
  }

  get tokenInfo () {
    return this.get('tokenInfo');
  }

  get ssoToken () {
    return this.get('ssoToken');
  }

  get activeNpoId () {
    return this.get('activeNpoId');
  }

  getFirstRoleFromNonprofitId (id: number) {
    for (const role in (this.tokenInfo?.nonprofitRoleMap || {})) {
      if (this.tokenInfo.nonprofitRoleMap[role].includes(id)) {
        return role;
      }
    }

    return '';
  }

  setTokenInfo (tokenInfo: UserLoginClaims) {
    if (tokenInfo) {
      const adaptedUserClaimsInfo = this.adaptUserClaimsInfo(tokenInfo);
      this.set('tokenInfo', adaptedUserClaimsInfo);
      this.set('userId', tokenInfo.userId);
      this.set('userEmail', tokenInfo.userName);
    } else {
      this.set('tokenInfo', null);
      this.set('userId', 0);
      this.set('userEmail', null);
    }
  }

  adaptUserClaimsInfo (tokenInfo: UserLoginClaims) {
    tokenInfo.NonprofitIdArr = Object.keys(tokenInfo.nonprofitRoleMap || {})
    .reduce((arr, str) => ([
      ...arr,
      ...tokenInfo.nonprofitRoleMap[str]
    ]), []);

    if (tokenInfo.role) {
      tokenInfo.role = tokenInfo.role instanceof Array ? tokenInfo.role : [tokenInfo.role];
    } else {
      tokenInfo.role = [];
    }

    return tokenInfo;
  }

  setSsoToken (ssoToken: string) {
    this.set('ssoToken', ssoToken);
  }

  setActiveNpoId (nonprofitId: number) {
    this.set('activeNpoId', nonprofitId);
  }

  hasToken () {
    return !!this.storage.jwt;
  }

  hasCurrentValidToken () {
    const currentToken = this.storage.jwt;

    // apply an offset to ensure we always have an up to date token
    const now = new Date(Date.now() + this.refreshOffset);

    // make sure the JWT is intact and that the token's expiration is in the future
    return this.isBbid ||
      (currentToken && !!this.parseJwt() && (new Date(currentToken.expiration) > now));
  }

  hasFutureValidToken () {
    const currentToken = this.storage.jwt;
    const now = new Date();

    // we have an intact JWT
    return this.isBbid ||
      (currentToken &&
        !!this.parseJwt() &&
        // and will be valid in the future if the token is expired
        !this.hasCurrentValidToken() &&
        // but the refreshToken is not
        (new Date(currentToken.refreshTokenExpiration) > now));
  }

  getIsLoggedIn () {
    return this.hasCurrentValidToken() || this.hasFutureValidToken();
  }

  getLatestToken (returnFullToken = false) {
    if (!this.latestProm || returnFullToken) {
      this.latestProm = new Promise<string|TokenResponse>(async (resolve) => {
        // pull the current token
        if (this.isBbid) {
          await this.setBbidJwt(true);
        }
        const currentToken = this.storage.jwt;
        // if we need to refresh, kick that off
        if (this.hasFutureValidToken() && !this.isBbid) {
          await this.doRefresh();
        }
        // if we have a valid token, return that
        if (this.isBbid || this.hasCurrentValidToken()) {
          return resolve(returnFullToken ? this.storage.jwt : this.storage.jwt.token);
        } else if (currentToken) {
          // if current token but it's not valid, we can remove it
          this.storage.revoke();
          this.setTokenInfo(null);
        }
        // otherwise we assume they never tried to log in and don't have a token
        resolve(null);
      }).then((val) => {
        this.latestProm = null;

        return val;
      }).catch(e => {
        console.error(e, {
          subMessage: 'Error logging out'
        });
        this.logout(true);

        throw e;
      });
    }

    return this.latestProm;
  }

  handleExpiredSession () {
    setTimeout(() => {
      return this.logout(true);
    }, 1000);
  }

  getIdentifier () {
    return this.storage.clientIdentifier;
  }

  extractTokenFromLocationAttribute (value: string) {
    return (value || '')
      .slice(1)
      .split('&')
      .reduce((obj, key) => ({
        ...obj,
        [key.split('=')[0]]: key.split('=')[1]
      }), {} as SSOToken);
  }

  async tokenSignin (token: TokenResponse) {
    this.storage.jwt = token;

    const jwt = this.parseJwt();

    await this.fetchUserClaims();

    if (jwt) {
      if (!environment.isLocalhost) {
        this.appInsights.setAuthenticatedUserContext(jwt.UserId, null, true);
      }
    }
  }

  async fetchUserClaims () {
    const response = await this.accountResources.getUserClaims();

    this.setTokenInfo(response.data);
  }


  handlePlatformDomainTransfer () {
    const platformHostRename = sessionStorage.getItem('platformHostRename');
    // Check to see if returning to local environment
    if (!!platformHostRename) {
      sessionStorage.removeItem('platformHostRename');
      const newHost = decodeURIComponent(platformHostRename);

      if (!this.permittedPlatformTransferRedirects.includes(newHost)) {
        return false;
      }

      location.hostname = newHost;

      return true;
    }

    return false;
  }

  logout = async (
    doRedirect = true
  )  => {
    if (!this.logoutTriggered) {
      this.logoutTriggered = true;

      this.accountService.resetAccountState();
      this.accountService.setAccessRemovedModalClosed(false);
      this.notificationService.setModalNotificationClosed(false);
      this.modalFactory.dismissAllOpen();
      this.timeout.stop();
      this.appInsights.clearAuthenticatedUserContext();
      if (this.isBbid) {
        this.setTokenInfo(null);
        this.storage.revoke();
        this.bbidService.logoutOfBbid();
      } else if (doRedirect) {
        this.setTokenInfo(null);
        this.storage.revoke();
        location.pathname = !!this.ssoToken ? '/admin/auth/logout' : '/login';
      }
    }

    return '';
  };

  parseJwt (token = this.storage.jwt?.token): TokenContent {
    if (!!token) {
      try {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = window.decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        var tokenContent = JSON.parse(jsonPayload) as TokenContent;

        return tokenContent;
      } catch (e) {
        console.warn('Failed to parse JWT', e, 'got:', token);
      }
    }

    return null;
  }

  async doRefresh (fetchUserClaims: boolean = false) {
    if (!this.isBbid) {
      const parsedToken = this.parseJwt();
      const identifier = this.storage.clientIdentifier;

      let result: TokenResponse;
      try {
        result = await this.refresh.refreshToken(
          this.storage.jwt.refreshToken,
          +parsedToken.UserId,
          identifier
        );
        if (!!result) {
          // we are good to go
          this.storage.jwt = result;
        } else {
          // our session has been terminated by another tab
          this.handleExpiredSession();
        }
      } catch (e) {
        this.appInsights.trackEvent('sso:debug', {
          event: 'REFRESH_FAILED_CATCH',
          identifier,
          userId: parsedToken.UserId,
          token: this.storage.jwt.refreshToken
        });

        this.handleExpiredSession();

        throw e;
      }
    }

    if (fetchUserClaims) {
      await this.fetchUserClaims();
    }
  }

  async platformAdminSsoExchange (): Promise<BlackbaudSsoError> {
    const token = this.extractTokenFromLocationAttribute(location.search);
    this.setSsoToken(token.code);
    try {
      const response = await this.retrieval.getPlatformAdminToken(
        token.code,
        this.getIdentifier()
      );
      const clientIdentifier = this.storage.clientIdentifier;
      this.storage.overrideClientIdentifier(clientIdentifier);
      await this.tokenSignin(response);

      return null;
    } catch (err) {
      const e = err as HttpErrorResponse;
      console.error(e);
      if (e?.error?.message === 'User does not have an account') {
        return BlackbaudSsoError.NoPlatformAccount;
      } else {
        return BlackbaudSsoError.Unknown;
      }
    }
  }

  /**
   * Gets and sets the BBID Token
   */
  async setBbidJwt (
    revokeIfFailed: boolean
  ) {
    const token = await this.bbidService.getToken();

    if (!!token) {
      const jwt: TokenResponse = {
        token,
        bbidToken: true,
        expiration: '',
        refreshToken: '',
        refreshTokenExpiration: ''
      };

      this.storage.jwt = jwt;

      return true;
    } else {
      if (revokeIfFailed) {
        this.storage.revoke();
      }

      return false;
    }
  }
}
