
import { Injectable } from '@angular/core';
import { CheckReissueDisplayModel, CompanyDisplayModel } from '@core/models/check-reissue.model';
import { NonprofitComplianceRecord } from '@core/models/compliance.model';
import { CsvDownloadResponse } from '@core/models/grid.model';
import { ActiveNonprofitsByEFG, ActiveNpoByEFGLinkPayload, BankCustomAttributesReportReturnModel, BbiNonprofitSubsectorReportModel, BbiNonprofitSubsectorReportPayload, BbiSubsectorFromApi, ChapterReportModel, ComplianceRecord, CustomComplianceCompany, EmailLogReportModel, FailedPaymentReturnedChecksReportDisplayReturnModel, ImportSummaryModel, InactiveNpoWithPendingDisbursementModel, JobsReportModel, MergedNonprofitsReportModel, NonprofitClassificationHierarchyRequestModel, NpoOnExclusionList, NpoWithDisbursementSuspended, OutstandingPaymentModel, ImportHistoryModel, ReportsAndReportTypesRequestModel, TransactionDetail, VettingApplicationsSubmittedOrCompletedReportDisplayModel, VettingRequestForReportingModel, NonprofitEligibileForGivingOverridesDto, RevokedNonprofitDto, RevokedNonprofitsSubdivisionsDto } from '@core/models/reporting.model';
import { VettingExpirationReport } from '@core/models/vetting.model';
import { HttpRestService } from '@core/services/http-rest.service';
import { SystemEmailsAPI } from '@core/typings/api/system-email.typing';
import { APIResponse, APIResult, PaginatedResponse, PaginationOptions } from '@yourcause/common';

@Injectable({ providedIn: 'root' })
export class ReportingResources {
  readonly downloadNonprofitExclusionListEndpoint = 'api/Nonprofit/NonprofitExclusionListDownload';
  readonly downloadVettingRequestsForReportingEndpoint = 'api/NonprofitVetting/VettingRequestsForReportingDownload';
  readonly downloadBBINonprofitSubsectorReportEndpoint = 'api/ReportingController/BBINonprofitSubsectorReportDownload';
  readonly downloadNonprofitChaptersReportEndpoint = 'api/NonprofitChapter/ChaptersReportDownload';
  readonly emailTemplatesForDownloadEndpoint = 'api/Email/EmailTemplatesForDownload';
  readonly emailReportForDownloadEndpoint = 'api/Email/EmailReportForDownload';
  readonly inactiveNPOsWithPendingDonationsDownloadEndpoint = 'api/ReportingController/InActiveNPOsWithPendingDonationsDownload';
  readonly failedPaymentReturnedChecksReportDownloadEndpoint = 'api/ReportingController/FailedPaymentReturnedChecksReportDownload';
  readonly bankCustomAttributesReportDownloadEndpoint = 'api/NonprofitBankInfo/BankCustomAttributesReportDownload';
  readonly vettingExpirationsDownloadEndpoint = 'api/vetting/VettingExpirationsDownload';
  readonly npoComplianceAffiliationReportDownloadEndpoint = 'api/Admin/NPOComplianceAffiliationReportDownload';
  readonly reissueCheckRequestsDownloadEndpoint = 'api/CheckReissue/ReissueCheckRequestsDownload';
  readonly complianceReportDownload = 'api/Compliance/ComplianceReportDownload';
  readonly nonprofitMergeReportDownload = 'api/Merge/NonprofitMergeReportDownload';
  readonly activeNonprofitsEFGReportByProcessingPartnerDownload = 'api/ReportingController/ActiveNonprofitsEFGReportByProcessingPartnerDownload';
  readonly activeNonprofitsEFGReportByRegistrationAuthorityDownload = 'api/ReportingController/ActiveNonprofitsEFGReportByRegistrationAuthorityDownload';
  readonly activeNonprofitsEFGReportByCountryDownload = 'api/ReportingController/ActiveNonprofitsEFGReportByCountryDownload';
  readonly activeNonprofitsEFGReportDownload = 'api/ReportingController/ActiveNonprofitsEFGReportDownload';
  readonly organizationsWithDisbursementSuspendedDownload = 'api/NonprofitCompliance/OrganizationsWithDisbursementSuspendedDownload';
  readonly outstandingPaymentTransactionsDownload = 'api/Nonprofit/Finance/OutstandingPaymentTransactionsDownload';
  readonly outstandingPaymentsWithDonationsDownload = 'api/Nonprofit/Finance/OutstandingPaymentsWithDonationsDownload';
  readonly nonprofitEligibileForGivingOverridesDownload = 'api/GetNonprofitEligibleForGivingOverridesDownload';
  readonly revokedNonprofitsDownload = 'api/nonprofits/revocations/report/download';

  constructor (
    private httpRestService: HttpRestService
  ) { }

  async getReportsAndReportTypes (
    paginationOptions: PaginationOptions<ReportsAndReportTypesRequestModel>
  ) {
    return this.httpRestService.post<APIResult<ReportsAndReportTypesRequestModel>>(
      'api/ReportingController/GetReportsAndReportTypes',
      {
        paginationOptions
      }
    );
  }

  async getReportingLink () {
    const response = await this.httpRestService.get<APIResponse>(
      'api/Nonprofit/GetCharityAdminReportLink'
    );

    return response.data;
  }

  async getCustomComplianceCompanies () {
    const response = await this.httpRestService.get<APIResponse<CustomComplianceCompany[]>>(
      'api/Company/GetCompaniesWithDonations'
    );

    return response.data;
  }

  async getYCProcessingPartnerNames () {
    const response = await this.httpRestService.get<APIResponse<string[]>>(
      'api/ReportingController/GetYCProcessingPartnerNames'
    );

    return response.data;
  }

  async getAllCompliances () {
    return this.httpRestService.get<APIResponse<NonprofitComplianceRecord[]>>(
      'api/Compliance/GetAllComplianceRecords'
    );
  }

  /**
   * Get Registration Authority/Vetting Authority Import History Report Data
   *
   * @param paginationOptions paging options
   * @returns paginated report data
   */
  getImportHistoryReportData (
    paginationOptions: PaginationOptions<ImportHistoryModel>
  ) {
    return this.httpRestService.post<APIResult<ImportHistoryModel>>(
      'api/ReportingController/GetRegAuthAndVettingAuthDataImportHistory',
      {
        paginationOptions
      }
    );
  }

  /**
   * Get Registration Authority/Vetting Authority Import Summary Report Data
   *
   * @param paginationOptions paging options
   * @param id DataImportTypeId
   * @returns paginated report data
   */
  getImportSummaryReportData (
    paginationOptions: PaginationOptions<ImportSummaryModel>,
    id: number
  ) {
    return this.httpRestService.post(
      'api/ReportingController/GetRegistrationAuthorityImportSummary',
      {
        dataImportTypeId: id,
        paginationOptions
      }
    );
  }

  getComplianceAndAffiliationsReport (
    paginationOptions: PaginationOptions<ComplianceRecord>,
    companyId: number
  ) {
    return this.httpRestService.post<APIResult<ComplianceRecord>>(
      'api/Admin/NPOComplianceAffiliationReport',
      {
        paginationOptions,
        companyId
      }
    );
  }

  async getComplianceAndAffiliationsReportCSV (
    paginationOptions: PaginationOptions<ComplianceRecord>,
    companyId: number
  ) {
    const response = await this.httpRestService.post<CsvDownloadResponse>(
      'api/Admin/NPOComplianceAffiliationReportDownload',
      {
        paginationOptions,
        companyId
      }
    );

    return response.data.content;
  }

  async getChapterReport (
    paginationOptions: PaginationOptions<ChapterReportModel>
  ) {
    return this.httpRestService.post<APIResult<ChapterReportModel>>(
      'api/NonprofitChapter/ChaptersReport',
      {
        paginationOptions
      }
    );
  }

  async getEmailTemplateReport (
    paginationOptions: PaginationOptions<SystemEmailsAPI.Email>,
    subjectTriggerFilter: string
  ) {
    const response = await this.httpRestService.post<APIResult<SystemEmailsAPI.Email>>(
      'api/Email/EmailTemplates',
      {
        paginationOptions,
        subjectTriggerFilter
      }
    );

    return response;
  }

  async getEmailClasses () {
    const endpoint = 'api/Email/GetEmailClasses';
    const payload = {
      paginationOptions: {
        returnAll: true,
        sortColumns: [{
          columnName: 'id',
          sortAscending: true
        }]}
    };

    const response = await this.httpRestService.post<APIResult<SystemEmailsAPI.EmailClass>>(
      endpoint,
      payload
    );

    return response.data.records;
  }

  async getOutstandingPaymentsReport (
    paginationOptions: PaginationOptions<OutstandingPaymentModel>,
    companyId: number,
    startDate: number
  ) {
    return this.httpRestService.post<APIResult<OutstandingPaymentModel>>(
      'api/Nonprofit/Finance/OutstandingDistinctPaymentsWithDonations',
      {
        paginationOptions,
        StartDate: startDate,
        CompanyId: companyId
      }
    );
  }

   async getOutstandingTransactions (
    paginationOptions: PaginationOptions<TransactionDetail>,
    nonprofitPaymentId: number
   ) {
     return this.httpRestService.post<APIResult<TransactionDetail>>(
      'api/Nonprofit/Finance/OutstandingPaymentTransactions',
      {
        paginationOptions,
        nonprofitPaymentId
      }
    );
  }

  /**
   * Get Inactive nonprofits with pending disbursements data
   *
   * @param paginationOptions paging options
   * @returns paginated report data
   */
  async getInactiveNposWithPendingDisbursements (
    paginationOptions: PaginationOptions<InactiveNpoWithPendingDisbursementModel>
  ) {
    const response = await this.httpRestService.post<APIResult<InactiveNpoWithPendingDisbursementModel>>(
      'api/ReportingController/InActiveNPOsWithPendingDonations',
      {
        paginationOptions
      }
    );

    return response;
  }

  async getMergedNonprofitsReport (
    paginationOptions: PaginationOptions<MergedNonprofitsReportModel>
  ) {
    return this.httpRestService.post<APIResult<InactiveNpoWithPendingDisbursementModel>>(
      'api/Merge/NonprofitMergeReport',
      {
        paginationOptions
      }
    );
  }

  async getNonprofitExclusionList (
    paginationOptions: PaginationOptions<NpoOnExclusionList>
  ) {
    const response = await this.httpRestService.post<APIResult<NpoOnExclusionList>>(
      'api/Nonprofit/NonprofitExclusionList',
      {
        paginationOptions
      }
    );

    return response;
  }

  async getNposWithDisbursementSuspended (
    paginationOptions: PaginationOptions<NpoWithDisbursementSuspended>
  ) {
    const endpoint = 'api/NonprofitCompliance/OrganizationsWithDisbursementSuspended';

    return this.httpRestService.post<APIResult<NpoWithDisbursementSuspended>>(
      endpoint,
      {
        paginationOptions
      }
    );
  }

  async getReissueCheckRequests (
    paginationOptions: PaginationOptions<CheckReissueDisplayModel>,
    companyId: number,
    checkReissueStatusId: number
  ) {
    return this.httpRestService.post<APIResult<CheckReissueDisplayModel>>(
      'api/CheckReissue/ReissueCheckRequests',
      {
        paginationOptions,
        StatusId: checkReissueStatusId,
        CompanyId: companyId
      }
    );
  }

  async getActiveNonprofitsEFGReportRegAuthAndCountry (
    paginationOptions: PaginationOptions<ActiveNonprofitsByEFG>
  ) {
    const endpoint = 'api/ReportingController/ActiveNonprofitsEFGReport';

    return this.httpRestService.post<APIResult<ActiveNonprofitsByEFG>>(
      endpoint,
      {
        paginationOptions
      }
    );
  }

  async getActiveNonprofitsEFGReportByRegistrationAuthority (
    paginationOptions: PaginationOptions<ActiveNonprofitsByEFG>
  ) {
    const endpoint = 'api/ReportingController/ActiveNonprofitsEFGReportByRegistrationAuthority';
    const response = await this.httpRestService.post<APIResult<ActiveNonprofitsByEFG>>(
      endpoint,
      {
        paginationOptions
      }
    );

    return response;
  }

  async getActiveNonprofitsEFGReportByCountry (
    paginationOptions: PaginationOptions<ActiveNonprofitsByEFG>
  ) {
    const endpoint = 'api/ReportingController/ActiveNonprofitsEFGReportByCountry';
    const response = await this.httpRestService.post<APIResult<ActiveNonprofitsByEFG>>(
      endpoint,
      {
        paginationOptions
      }
    );

    return response;
  }

  getActiveNonprofitsEFGReportByProcessor (
    paginationOptions: PaginationOptions<ActiveNonprofitsByEFG>
  ) {
    const endpoint = '/api/ReportingController/ActiveNonprofitsEFGReportByProcessingPartner';

    return this.httpRestService.post<APIResult<ActiveNonprofitsByEFG>>(
      endpoint,
      {
        paginationOptions
      }
    );
  }

  async getJobsReport (paginationOptions: PaginationOptions<JobsReportModel>) {
    return this.httpRestService.post<{ data: PaginatedResponse<JobsReportModel> }>(
      'api/JobController/JobReport',
      {
        paginationOptions
      }
    );
  }

  async getEmailReport (
    paginationOptions: PaginationOptions<EmailLogReportModel>
  ) {
    const endpoint = 'api/Email/EmailReport';

    return this.httpRestService.post<APIResult<EmailLogReportModel>>(
      endpoint,
      {
        paginationOptions
      }
    );
  }

  async getEmailPreview (emailId: number) {
    const endpoint = `api/Email/GetEmailPreview?EmailLogId=${emailId}`;

    return this.httpRestService.get<APIResponse>(endpoint);
  }

  async getNonprofitsBasedOnVettingExpiredDay (
    vettingAuthorityId: number,
    expireDays: number,
    paginationOptions: PaginationOptions<VettingExpirationReport>
  ) {
    const endpoint = 'api/vetting/VettingExpirations';
    const response = await this.httpRestService.post<APIResult<VettingExpirationReport>>(
      endpoint,
      {
        VettingAuthorityId: vettingAuthorityId,
        ExpireDays: expireDays,
        paginationOptions
      }
    );

    return response;
  }

  async getVettingRequestsForReporting (
    paginationOptions: PaginationOptions<VettingRequestForReportingModel>
  ) {
    const endpoint = 'api/NonprofitVetting/VettingRequestsForReporting';
    const response = await this.httpRestService.post<APIResult<VettingRequestForReportingModel>>(
      endpoint,
      {
        paginationOptions
      }
    );

    return response;
  }

  async getBankCustomAttributesReport (
    paginationOptions: PaginationOptions<BankCustomAttributesReportReturnModel>
  ) {
    const endpoint = 'api/NonprofitBankInfo/BankCustomAttributesReport';
    const response = await this.httpRestService.post<APIResult<BankCustomAttributesReportReturnModel>>(
      endpoint,
      {
        paginationOptions
      }
    );

    return response;
  }

  async getBankCustomAttributesReportDownload (
    paginationOptions: PaginationOptions<BankCustomAttributesReportReturnModel>
  ) {
    const endpoint = 'api/NonprofitBankInfo/BankCustomAttributesReportDownload';

    const response = await this.httpRestService.post<CsvDownloadResponse>(
      endpoint,
      {
        paginationOptions
      }
    );

    return response.data.content;
  }

  async getBBINonprofitSubsectorReport (
    payload: BbiNonprofitSubsectorReportPayload
  ) {
    const endpoint = 'api/ReportingController/BBINonprofitSubsectorReport';
    const response = await this.httpRestService.post<APIResult<BbiNonprofitSubsectorReportModel>>(
      endpoint,
      payload
    );

    return response;
  }


  /**
   * Get nonprofits with failed payment and no active admin
   *
   * @param paginationOptions paging options
   * @returns paginated report data
   */
  async failedPaymentReturnedChecksReport (
    paginationOptions: PaginationOptions<FailedPaymentReturnedChecksReportDisplayReturnModel>
  ) {
    const endpoint = 'api/ReportingController/FailedPaymentReturnedChecksReport';
    const response = await this.httpRestService.post<APIResult<FailedPaymentReturnedChecksReportDisplayReturnModel>>(
      endpoint,
      {
        paginationOptions
      }
    );

    return response;
  }

  async fetchAllCompanies () {
    const endpoint = 'api/Company/GetCompanies';
    const response = await this.httpRestService.get<APIResponse<CompanyDisplayModel[]>>(
      endpoint
    );

    return response.data;
  }

  async uploadCharityIdList (charityIds: number[]) {
    return this.httpRestService.post(
      `api/Admin/UploadCharityIdList`,
      charityIds
    );
  }

  async getBBISubsectorInfoList () {
    const endpoint = 'api/NonprofitClassification/GetBBISubsectorInfoList';
    const response = await this.httpRestService.get<APIResponse<BbiSubsectorFromApi[]>>(
      endpoint
    );

    return response.data;
  }

  async getRevokedNonprofitsSubdivisionsList () {
    const endpoint = 'api/nonprofits/revocations/revokedNonprofitsSubdivisions';

    const response = await this.httpRestService.get<APIResponse<RevokedNonprofitsSubdivisionsDto>>(
      endpoint
    );

    return response.data;
  }

  getNonprofitClassificationMapReport (
    paginationOptions: PaginationOptions<NonprofitClassificationHierarchyRequestModel>
  ) {
    const endpoint = 'api/NonprofitClassification/NonprofitClassificationMapReport';
    const response = this.httpRestService.post<APIResult<NonprofitClassificationHierarchyRequestModel>>(
      endpoint,
      {
        paginationOptions
      }
    );

    return response;
  }

  async getNonprofitClassificationMapDownload (
    paginationOptions: PaginationOptions<NonprofitClassificationHierarchyRequestModel>
  ) {
    const endpoint = 'api/NonprofitClassification/NonprofitClassificationMapReport';
    const response = await this.httpRestService.post<CsvDownloadResponse>(
      endpoint,
      {
        paginationOptions
      }
    );

    return response.data.content;
  }

  getVettingApplicationsSubmittedOrCompletedReportDisplay (
    paginationOptions: PaginationOptions<VettingApplicationsSubmittedOrCompletedReportDisplayModel>,
    startDate: string,
    endDate: string
  ) {
    const endpoint = 'api/ReportingController/SubmittedOrCompletedVettingApplications';
    const response = this.httpRestService.post<APIResult<VettingApplicationsSubmittedOrCompletedReportDisplayModel>>(
      endpoint,
      {
        paginationOptions,
        startDate,
        endDate
      }
    );

    return response;
  }

  queueActiveNonprofitsEFGReport (payload: ActiveNpoByEFGLinkPayload) {
    const endpoint = '/api/ActiveNonprofitsEFGReports/QueueActiveNonprofitsEFGReport';

    return this.httpRestService.post<APIResult<any>>(
      endpoint,
      payload
    );
  }

  /**
   * Get Nonprofit whose EFG flag has been overridden
   * @param paginationOptions
   * @returns paginated report of nonprofits whose EFG flag has been overridden
   */
  getEligibilityForGivingOverrides (
    paginationOptions: PaginationOptions<NonprofitEligibileForGivingOverridesDto>
  ) {
    const response = this.httpRestService.post<APIResult<NonprofitEligibileForGivingOverridesDto>>(
      'api/GetNonprofitEligibleForGivingOverrides',
      {
        paginationOptions
      }
    );

    return response;
  }

  /**
   * Get Nonprofits that have been revoked
   * @param paginationOptions
   * @returns paginated report of nonprofits that are revoked
   */
  getRevokedNonprofits (
    paginationOptions: PaginationOptions<RevokedNonprofitDto>,
    revokedByStateCode: string
  ) {
    const response = this.httpRestService.post<APIResult<PaginationOptions<RevokedNonprofitDto>>>(
      'api/nonprofits/revocations/report/display',
      {
        paginationOptions,
        revokedByStateCode
      }
    );

    return response;
  }
}
